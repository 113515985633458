// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

* {
  font-family: 'Inter', sans-serif;
}
h1, h2, h3 {
  font-weight: 700; /* Bold */
}

p, ion-item {
  font-weight: 400; /* Regular */
}


.w-100 {
  width: 100%;
}

.small-chip {
  height: 10px;
  font-size: 10px;
}

.validator-error {
  color: var(--ion-color-danger);
  align-self: start;
}

.secondary-text {
  color: var(--ion-color-secondary);
}

@media all and (max-width: 565px) {
  .mat-datepicker-popup {
    width: 100% !important;
    bottom: auto !important;
    left: 0 !important;
    top: 0 !important;
  }
  .mat-datepicker-content {
    width: 100% !important;
  }
  .mat-calendar {
    width: 100% !important;
    height: 100% !important;
  }
}

ion-icon.close-icon {
  font-size: 28px;
  padding-right: 10px;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: transparent !important;
  bottom: 0 !important;
}
.mat-form-field-wrapper {
  padding-bottom: 0.7em !important;
}

.chart-legend .legend-labels {
  text-align: center !important;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

ion-title {
  color: var(--ion-color-secondary);
}

// Icon
.mat-datepicker-toggle-active{
  color: var(--ion-color-secondary)
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  border-color:rgba(var(--ion-color-secondary-rgb), 0.4)
}

.mat-calendar-body-disabled>.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  border-color:rgba(var(--ion-color-secondary-rgb), 0.15)
}

.mat-calendar-body-selected{
  color:#fff;
  background-color: var(--ion-color-secondary);
}

.mat-calendar-body-disabled>.mat-calendar-body-selected{
  background-color: rgba(var(--ion-color-secondary-rgb), .4)
}

.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
  background-color: var(--ion-color-secondary-rgb);
}

@media(hover: hover){
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    background-color: rgba(var(--ion-color-secondary-rgb), .3)
  }
}
